<template>
  <uds-simple-dropdown ref="dropdownfiscal" style="width: fit-content">
    <template #inputContent>
      <div class="fiscal-period-select">
        <uds-icon v-if="displayAsInput" icon-name="calendar_today" style="margin: 0px 8px" />
        <div v-else style="margin-left: 8px" />

        <span class="fiscal-current-date-range">
          {{ currentDateRange }}
        </span>

        <uds-icon
          :icon-name="isDropdownOpen ? 'expand_less' : 'expand_more'"
          size="small"
          style="margin-left: 8px; margin-right: 8px"
        />
      </div>
    </template>
    <template #dropdownContent>
      <div class="fiscal-period-list-wrapper scrollbar">
        <CommonsSelectsFiscalePeriodFiscalPeriodsList @change-period="changePeriod($event)" />
      </div>
    </template>
  </uds-simple-dropdown>
</template>

<script setup lang="ts">
import type { IExercice } from "@silexpert/core";

const exerciceStore = useExerciceStore();
const props = defineProps<{
  startDate: string;
  endDate: string;
  displayAsInput?: boolean;
}>();

const emit = defineEmits<{
  (
    e: "change-period",
    dates: { dateStart: string | null; dateEnd: string | null; idExercice: number | null },
  ): void;
}>();

const dayjs = useDayjs();

const dropdownfiscal = ref<any>(null);
const isDropdownOpen = computed(() => dropdownfiscal.value?.isMenuShown);

// call from parent
defineExpose({
  closeDropdown,
});
function closeDropdown() {
  dropdownfiscal.value.isMenuShown = false;
}

const currentDateRange = computed(() => {
  const start = dayjs(props.startDate).format("DD/MM/YYYY");
  const end = dayjs(props.endDate).format("DD/MM/YYYY");
  return `${start} - ${end}`;
});

function changePeriod({
  dateStart,
  dateEnd,
  idExercice,
}: {
  dateStart: string | null;
  dateEnd: string | null;
  idExercice: number | null;
}) {
  emit("change-period", { dateStart, dateEnd, idExercice });
  exerciceStore.current =
    exerciceStore.exercices.find((e: IExercice) =>
      idExercice
        ? idExercice === e.id
        : dayjs(e.startDate).isSame(dateStart) && dayjs(e.endDate).isSame(dateEnd),
    ) ?? null;
}
</script>

<style lang="scss" scoped>
.fiscal-period-container {
  display: flex;
  max-width: 260px;
}

.fiscal-period-select {
  display: flex;
  min-width: 200px;
  max-width: 250px;
  height: 30px;
  border-radius: $uds-radius-1;
  background-color: $uds-neutral-200;
  align-items: center;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.fiscal-current-date-range {
  font-style: normal;
  font-weight: $uds-weight-450;
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  color: #7e7f86;
}

.fiscal-period-list-wrapper {
  max-height: 300px;
}

.fiscal-period-input-container {
  :deep(.fpl-container) {
    min-width: 200px !important;
  }
  .fiscal-period-select {
    height: 40px;
    border-radius: $uds-radius-1;
    background-color: $uds-neutral-200;
    color: $uds-neutral-800;
    .fiscal-current-date-range {
      font-size: 16px;
    }
  }
  :deep(.mdi-container) {
    margin-right: 14px;
  }
}
</style>
